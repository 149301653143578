import React from "react";
import theme from "theme";
import { Theme, Image, Text, LinkBox, Box, Icon, Button, Link, Span, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FiMenu } from "react-icons/fi";
import { MdArrowBack, MdArrowForward } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				SPhotonix — Revolutionizing Data Storage & Photonics
			</title>
			<meta name={"description"} content={"Discover SPhotonix’s cutting-edge data storage solutions. We specialize in 5D optical storage technology, pushing the boundaries of durability, scalability, and future-proof innovation for enterprise storage systems. Partner with us to bring next-generation data storage to market."} />
			<meta property={"og:title"} content={"SPhotonix — Revolutionizing Data Storage & Photonics"} />
			<meta property={"og:description"} content={"SPhotonix pioneers the future of data storage with breakthrough 5D optical technology. Our innovative solutions offer durable, scalable, and ultra-secure storage for enterprises looking to safeguard their data for decades. Explore how we are revolutionizing the industry."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/sphotonix-new-logo-white.svg?v=2024-09-17T17:56:51.435Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav32w.png?v=2024-09-23T14:28:13.820Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav270w.png?v=2024-09-23T14:27:58.132Z"} />
			<meta name={"msapplication-TileColor"} content={"#ffffff"} />
		</Helmet>
		<Box
			min-width="100px"
			sm-min-width="0px"
			sm-min-height="0px"
			sm-height="52px"
			position="fixed"
			width="100%"
			left={0}
			z-index="200"
			height="88px"
		>
			<Box
				position="absolute"
				right={0}
				bottom={0}
				top={0}
				left={0}
				z-index="100"
				height="88px"
				sm-height="52px"
				padding="0px 16px 0px 16px"
			>
				<Box
					height="88px"
					flex-direction="row"
					justify-content="space-between"
					align-items="center"
					grid-gap="190px"
					display="flex"
					flex-wrap="no-wrap"
					sm-padding="0px 16px 0px 16px"
					sm-height="52px"
					sm-display="flex"
					sm-grid-column-gap="0px"
					sm-grid-row-gap="0px"
					grid-column-gap={0}
					md-padding="24px 16px 24px 16px"
					lg-padding="24px 16px 24px 16px"
					margin="0px auto 0px auto"
					max-width="1348px"
					position="relative"
				>
					<Box
						display="flex"
						padding="12px 0"
						justify-content="space-between"
						align-items="flex-start"
						flex-direction="row"
						sm-align-items="center"
						sm-flex-direction="row"
						sm-justify-content="flex-start"
						md-width="50%"
						md-justify-content="flex-start"
						sm-height="auto"
						sm-padding="0px 0 0px 0"
						flex="0 0 33.33%"
						sm-flex="0 0 auto"
						lg-flex="0 0 100px"
					>
						<LinkBox
							flex-direction="row"
							href="/index"
							display="flex"
							grid-gap="12px"
							sm-height="min-content"
						>
							<Image src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/sphotonix-new-logo.svg?v=2024-09-17T17:58:03.987Z" height="50px" sm-height="28px" sm-display="block" />
							<Text
								color="white"
								font="600 27px Epilogue"
								line-height="30px"
								word-wrap="break-word"
								sm-font="600 15px/24px Epilogue"
								sm-margin="0px 0px 0px 0px"
							>
								SPhotonix
							</Text>
						</LinkBox>
					</Box>
					<Components.QuarklycommunityKitMobileSidePanel
						menuPosition="full"
						sm-width="50%"
						md-width="50%"
						flex="0 0 66.66%"
						position="static"
						sm-flex="0 0 auto"
						sm-flex-direction="column"
						sm-align-items="flex-end"
						lg-flex-direction="row-reverse"
						breakpoint="lg"
						lg-width="100%"
						lg-position="relative"
						md-flex-direction="row-reverse"
					>
						<Override slot="Children" lg-display="flex" lg-height="100%" />
						<Override
							slot="Content"
							padding="0px 0px 0px 0px"
							background="rgba(255, 255, 255, 0)"
							sm-background="white"
							lg-background="white"
							max-width="none"
							max-height="none"
						/>
						<Override
							slot="Button Text"
							font="normal 600 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
							text-transform="uppercase"
							letter-spacing="1px"
							sm-font="normal 600 14px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
							sm-margin="0px 2px 0px 0px"
							lg-margin="0px 0px 0px 0px"
							lg-display="none"
						/>
						<Override
							slot="Button Icon :closed"
							category="fi"
							icon={FiMenu}
							size="32px"
							padding="5px 7px 5px 7px"
							border-radius="50px"
						/>
						<Override
							slot="Button Icon"
							width="28px"
							height="28px"
							category="fi"
							icon={FiMenu}
							color="--dark"
							size="24px"
							lg-width="32px"
							lg-height="32px"
							lg-color="#ffffff"
							md-padding="1px 1px 1px 1px"
							lg-padding="1px 1px 1px 1px"
						/>
						<Override
							slot="Cross"
							lg-width="32px"
							lg-height="32px"
							size="32px"
							top="24px"
							right="24px"
						/>
						<Override slot="Wrapper" position="static" lg-position="relative" />
						<Override slot="Overlay" />
						<Box
							align-items="center"
							justify-content="flex-end"
							lg-display="flex"
							lg-flex-direction="column"
							lg-flex-wrap="no-wrap"
							lg-align-items="center"
							lg-justify-content="flex-start"
							lg-width="100%"
							lg-height="100%"
							lg-grid-row-gap={0}
							flex-direction="row"
							display="flex"
						>
							<Box min-width="100px" min-height="100px" lg-width="100%">
								<Box
									min-width="100px"
									min-height="100px"
									lg-padding="24px 64px 0px 16px"
									display="none"
									lg-display="block"
								>
									<LinkBox
										flex-direction="row"
										href="/index"
										display="flex"
										grid-gap="12px"
										lg-align-items="center"
										lg-display="flex"
										lg-flex-direction="row"
										lg-justify-content="flex-start"
									>
										<Image src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/sphotonix-new-logo-white.svg?v=2024-09-17T17:56:51.435Z" display="none" lg-height="28px" lg-display="block" />
										<Text
											color="white"
											font="600 21px Epilogue"
											line-height="30px"
											word-wrap="break-word"
											lg-font="600 15px/24px Epilogue"
											lg-margin="0px 0px 0px 0px"
											lg-color="black"
										>
											SPhotonix
										</Text>
									</LinkBox>
								</Box>
							</Box>
							<Box
								height="48px"
								justify-content="flex-start"
								align-items="center"
								grid-gap="32px"
								display="inline-flex"
								lg-flex-direction="column"
								lg-flex-wrap="no-wrap"
								lg-align-items="flex-start"
								lg-justify-content="center"
								lg-height="min-content"
								position="absolute"
								left="50%"
								transform="translateX(-50%)"
								lg-width="100%"
								lg-position="static"
								lg-margin="0px auto 0px auto"
								lg-transform="none"
								lg-display="flex"
								lg-grid-row-gap="0px"
								lg-left="25%"
							>
								<Components.Dropdown height="100%" activationMode="hover" lg-width="100%" lg-position="static">
									<Override
										slot="Menu"
										display="flex"
										align-items="center"
										justify-content="center"
										flex-direction="column"
										flex-wrap="no-wrap"
										padding="0px 0px 12p0pxx 0px"
										lg-width="100%"
										lg-top="80px"
										lg-box-shadow="none"
										lg-padding="0px 0px 50px 0px"
										width="240px"
									>
										<Box padding="0px 16px 24px 16px" display="none" lg-display="block">
											<Components.DropdownClose
												lg-flex-direction="row"
												lg-justify-content="flex-start"
												color="--accentRed"
												flex-direction="row"
												justify-content="flex-start"
											>
												<Icon category="md" icon={MdArrowBack} lg-height="100%" margin="0px 6px 0px 0px" />
												<Text margin="0px 0px 0px 0px">
													Company
												</Text>
											</Components.DropdownClose>
										</Box>
										<LinkBox
											font="400 15px/24px Epilogue"
											word-wrap="break-word"
											text-decoration-line="initial"
											lg-color="black"
											color="inherit"
											lg-width="100%"
											lg-border-color="#D8D8D8"
											lg-border-width="0 0 1px 0"
											lg-border-style="solid"
											lg-font="500 17px/26px Epilogue"
											lg-padding="16px 16px 15px 16px"
											lg-flex-direction="row"
											lg-justify-content="flex-start"
											align-items="flex-start"
											padding="16px 16px 15px 16px"
											border-width="0 0 1px 0"
											border-color="#D8D8D8"
											border-style="solid"
											hover-color="--accentRed"
											href="/about-us"
										>
											<Text margin="0px 0px 0px 0px" font="--bodyText17Medium">
												About us
											</Text>
										</LinkBox>
										<LinkBox
											font="400 15px/24px Epilogue"
											word-wrap="break-word"
											text-decoration-line="initial"
											lg-color="black"
											color="inherit"
											lg-width="100%"
											lg-border-color="#D8D8D8"
											lg-border-width="0 0 1px 0"
											lg-border-style="solid"
											lg-font="500 17px/26px Epilogue"
											lg-padding="16px 16px 15px 16px"
											lg-flex-direction="row"
											lg-justify-content="flex-start"
											align-items="flex-start"
											padding="16px 16px 15px 16px"
											border-style="solid"
											border-width="0 0 1px 0"
											border-color="#D8D8D8"
											hover-color="--accentRed"
											href="/brief-history"
											display="none"
										>
											<Text margin="0px 0px 0px 0px" font="--bodyText17Medium">
												Brief history
											</Text>
										</LinkBox>
										<LinkBox
											font="400 15px/24px Epilogue"
											word-wrap="break-word"
											text-decoration-line="initial"
											lg-color="black"
											color="inherit"
											lg-width="100%"
											lg-border-color="#D8D8D8"
											lg-border-width="0 0 1px 0"
											lg-border-style="solid"
											lg-font="500 17px/26px Epilogue"
											lg-padding="16px 16px 15px 16px"
											lg-flex-direction="row"
											lg-justify-content="flex-start"
											align-items="flex-start"
											padding="16px 16px 15px 16px"
											border-color="#D8D8D8"
											border-width="0 0 1px 0"
											border-style="solid"
											hover-color="--accentRed"
											href="/team"
										>
											<Text margin="0px 0px 0px 0px" font="--bodyText17Medium">
												Team
											</Text>
										</LinkBox>
										<LinkBox
											font="400 15px/24px Epilogue"
											word-wrap="break-word"
											text-decoration-line="initial"
											lg-color="black"
											color="inherit"
											lg-width="100%"
											lg-border-color="#D8D8D8"
											lg-border-width="0 0 1px 0"
											lg-border-style="solid"
											lg-font="500 17px/26px Epilogue"
											lg-padding="16px 16px 15px 16px"
											lg-flex-direction="row"
											lg-justify-content="flex-start"
											align-items="flex-start"
											padding="16px 16px 15px 16px"
											border-width="0 0 1px 0"
											border-color="#D8D8D8"
											border-style="solid"
											hover-color="--accentRed"
											href="/recognition"
											display="none"
										>
											<Text margin="0px 0px 0px 0px" font="--bodyText17Medium">
												Recognition
											</Text>
										</LinkBox>
										<LinkBox
											font="400 15px/24px Epilogue"
											word-wrap="break-word"
											text-decoration-line="initial"
											lg-color="black"
											color="inherit"
											lg-width="100%"
											lg-border-color="#D8D8D8"
											lg-border-width="0 0 1px 0"
											lg-border-style="solid"
											lg-font="500 17px/26px Epilogue"
											lg-padding="16px 16px 15px 16px"
											lg-flex-direction="row"
											lg-justify-content="flex-start"
											align-items="flex-start"
											padding="16px 16px 16px 16px"
											hover-color="--accentRed"
											href="/facilities"
											display="none"
										>
											<Text margin="0px 0px 0px 0px" font="--bodyText17Medium">
												Facilities
											</Text>
										</LinkBox>
									</Override>
									<Override
										slot="Container"
										color="white"
										height="100%"
										display="flex"
										align-items="center"
										lg-width="100%"
										font="--bodyText15Regular"
									/>
									<LinkBox
										word-wrap="break-word"
										text-decoration-line="initial"
										lg-color="black"
										lg-width="100%"
										lg-border-color="#D8D8D8"
										lg-border-width="0 0 1px 0"
										lg-border-style="solid"
										lg-padding="16px 16px 15px 16px"
										lg-flex-direction="row"
										lg-justify-content="space-between"
										lg-display="flex"
										lg-flex-wrap="no-wrap"
										lg-align-items="center"
										lg-hover-color="--accentRed"
										font="--bodyText15Regular"
										color="white"
									>
										<Text margin="0px 0px 0px 0px" lg-font="500 17px/26px Epilogue" color="inherit" font="20px/24px Epilogue">
											Company
										</Text>
										<Icon
											category="md"
											icon={MdArrowForward}
											lg-height="100%"
											lg-display="flex"
											display="none"
										/>
									</LinkBox>
								</Components.Dropdown>
								<Components.Dropdown height="100%" activationMode="hover" lg-width="100%" lg-position="static">
									<Override
										slot="Menu"
										display="flex"
										align-items="center"
										justify-content="center"
										flex-direction="column"
										flex-wrap="no-wrap"
										padding="0px 0px 12p0pxx 0px"
										lg-width="100%"
										lg-top="80px"
										lg-box-shadow="none"
										lg-padding="0px 0px 50px 0px"
										width="260px"
									>
										<Box padding="0px 16px 24px 16px" sm-display="block" display="none" lg-display="block">
											<Components.DropdownClose
												lg-flex-direction="row"
												lg-justify-content="flex-start"
												color="--accentRed"
												flex-direction="row"
												justify-content="flex-start"
											>
												<Icon category="md" icon={MdArrowBack} lg-height="100%" margin="0px 6px 0px 0px" />
												<Text margin="0px 0px 0px 0px">
													Technology
												</Text>
											</Components.DropdownClose>
										</Box>
										<LinkBox
											font="400 15px/24px Epilogue"
											word-wrap="break-word"
											text-decoration-line="initial"
											lg-color="black"
											color="inherit"
											lg-width="100%"
											lg-border-color="#D8D8D8"
											lg-border-width="0 0 1px 0"
											lg-border-style="solid"
											lg-font="500 17px/26px Epilogue"
											lg-padding="16px 16px 15px 16px"
											lg-flex-direction="row"
											lg-justify-content="flex-start"
											align-items="flex-start"
											padding="16px 16px 15px 16px"
											border-width="0 0 1px 0"
											border-color="#D8D8D8"
											border-style="solid"
											hover-color="--accentRed"
											href="/data-storage-innovation"
										>
											<Text margin="0px 0px 0px 0px" font="500 17px/26px Epilogue">
												Data Storage Innovation
											</Text>
										</LinkBox>
										<LinkBox
											font="400 15px/24px Epilogue"
											word-wrap="break-word"
											text-decoration-line="initial"
											lg-color="black"
											color="inherit"
											lg-width="100%"
											lg-border-color="#D8D8D8"
											lg-border-width="0 0 1px 0"
											lg-border-style="solid"
											lg-font="500 17px/26px Epilogue"
											lg-padding="16px 16px 15px 16px"
											lg-flex-direction="row"
											lg-justify-content="flex-start"
											align-items="flex-start"
											padding="16px 16px 15px 16px"
											border-style="solid"
											border-width="0 0 1px 0"
											border-color="#D8D8D8"
											hover-color="--accentRed"
											href="/optical-device-fabrication"
										>
											<Text margin="0px 0px 0px 0px" font="500 17px/26px Epilogue">
												Optical Device Fabrication
											</Text>
										</LinkBox>
										<LinkBox
											font="400 15px/24px Epilogue"
											word-wrap="break-word"
											text-decoration-line="initial"
											lg-color="black"
											color="inherit"
											lg-width="100%"
											lg-border-color="#D8D8D8"
											lg-border-width="0 0 1px 0"
											lg-border-style="solid"
											lg-font="500 17px/26px Epilogue"
											lg-padding="16px 16px 15px 16px"
											lg-flex-direction="row"
											lg-justify-content="flex-start"
											align-items="flex-start"
											padding="16px 16px 15px 16px"
											border-color="#D8D8D8"
											border-width="0 0 1px 0"
											border-style="solid"
											hover-color="--accentRed"
											display="none"
										>
											<Text margin="0px 0px 0px 0px" font="500 17px/26px Epilogue">
												Team
											</Text>
										</LinkBox>
										<LinkBox
											font="400 15px/24px Epilogue"
											word-wrap="break-word"
											text-decoration-line="initial"
											lg-color="black"
											color="inherit"
											lg-width="100%"
											lg-border-color="#D8D8D8"
											lg-border-width="0 0 1px 0"
											lg-border-style="solid"
											lg-font="500 17px/26px Epilogue"
											lg-padding="16px 16px 15px 16px"
											lg-flex-direction="row"
											lg-justify-content="flex-start"
											align-items="flex-start"
											padding="16px 16px 15px 16px"
											border-width="0 0 1px 0"
											border-color="#D8D8D8"
											border-style="solid"
											hover-color="--accentRed"
											display="none"
										>
											<Text margin="0px 0px 0px 0px" font="500 17px/26px Epilogue">
												Recognition
											</Text>
										</LinkBox>
										<LinkBox
											font="400 15px/24px Epilogue"
											word-wrap="break-word"
											text-decoration-line="initial"
											lg-color="black"
											color="inherit"
											lg-width="100%"
											lg-border-color="#D8D8D8"
											lg-border-width="0 0 1px 0"
											lg-border-style="solid"
											lg-font="500 17px/26px Epilogue"
											lg-padding="16px 16px 15px 16px"
											lg-flex-direction="row"
											lg-justify-content="flex-start"
											align-items="flex-start"
											padding="16px 16px 16px 16px"
											hover-color="--accentRed"
											display="none"
										>
											<Text margin="0px 0px 0px 0px" font="500 17px/26px Epilogue">
												Facilities
											</Text>
										</LinkBox>
									</Override>
									<Override
										slot="Container"
										color="white"
										height="100%"
										display="flex"
										align-items="center"
										lg-width="100%"
										font="--bodyText15Regular"
									/>
									<LinkBox
										word-wrap="break-word"
										text-decoration-line="initial"
										lg-color="black"
										color="inherit"
										lg-width="100%"
										lg-border-color="#D8D8D8"
										lg-border-width="0 0 1px 0"
										lg-border-style="solid"
										lg-padding="16px 16px 15px 16px"
										lg-flex-direction="row"
										lg-justify-content="space-between"
										lg-display="flex"
										lg-flex-wrap="no-wrap"
										lg-align-items="center"
										lg-hover-color="--accentRed"
										font="--bodyText15Regular"
									>
										<Text margin="0px 0px 0px 0px" lg-font="500 17px/26px Epilogue" font="20px/24px Epilogue">
											Technology
										</Text>
										<Icon
											category="md"
											icon={MdArrowForward}
											lg-height="100%"
											lg-display="flex"
											display="none"
										/>
									</LinkBox>
								</Components.Dropdown>
								<LinkBox
									word-wrap="break-word"
									text-decoration-line="initial"
									lg-color="black"
									lg-width="100%"
									lg-border-color="#D8D8D8"
									lg-border-width="0 0 1px 0"
									lg-border-style="solid"
									lg-font="500 17px/26px Epilogue"
									lg-padding="16px 16px 15px 16px"
									lg-flex-direction="row"
									lg-justify-content="flex-start"
									lg-hover-color="--accentRed"
									color="white"
									font="--bodyText15Regular"
									href="/memory-crystal"
									display="none"
								>
									<Text margin="0px 0px 0px 0px" lg-color="inherit">
										Memory cristal
									</Text>
								</LinkBox>
								<LinkBox
									font="--bodyText15Regular"
									word-wrap="break-word"
									text-decoration-line="initial"
									lg-color="black"
									lg-width="100%"
									lg-border-color="#D8D8D8"
									lg-border-width="0 0 1px 0"
									lg-border-style="solid"
									lg-font="500 17px/26px Epilogue"
									lg-padding="16px 16px 15px 16px"
									lg-flex-direction="row"
									lg-justify-content="flex-start"
									lg-hover-color="--accentRed"
									color="white"
									href="/contact"
								>
									<Text
										margin="0px 0px 0px 0px"
										lg-font="500 17px/26px Epilogue"
										color="inherit"
										font="20px/24px Epilogue"
										position="static"
									>
										Contact
									</Text>
								</LinkBox>
							</Box>
							<Box
								width="50%"
								justify-content="flex-end"
								align-items="center"
								grid-gap="32px"
								display="flex"
								lg-display="flex"
								lg-flex-direction="column"
								lg-flex-wrap="no-wrap"
								lg-align-items="center"
								lg-justify-content="flex-end"
								lg-height="100%"
								flex-direction="row"
								lg-width="100%"
								lg-padding="16px 16px 16px 16px"
							>
								<Button
									type="link"
									border-radius="8px"
									border="1px white solid"
									backdrop-filter="blur(6px)"
									justify-content="flex-start"
									align-items="flex-start"
									gap="10px"
									display="inline-flex"
									background="none"
									focus-box-shadow="none"
									text-decoration-line="initial"
									lg-border-color="black"
									lg-color="black"
									lg-width="100%"
									lg-justify-content="center"
									href="/memory-crystal"
									font="--bodyText17Regular"
									color="white"
								>
									Order 5D Memory Crystal™
								</Button>
							</Box>
						</Box>
					</Components.QuarklycommunityKitMobileSidePanel>
				</Box>
			</Box>
		</Box>
		<Box
			min-width="100px"
			sm-min-width="0px"
			sm-min-height="0px"
			sm-height="52px"
			position="fixed"
			width="100%"
			left={0}
			background="linear-gradient(67.6deg, #0B0908 60.29%, #4A2528 113.2%) 0% 0%/100% 1020px"
			height="88px"
			z-index="90"
		>
			<Box
				position="absolute"
				left={0}
				bottom={0}
				top={0}
				min-width="100px"
				max-width="1190px"
				width="100%"
				background="linear-gradient(90deg, #0B0808 0%, rgba(11, 8, 8, 0) 100%)"
				display="block"
				sm-display="none"
				height="88px"
			/>
			<Box
				position="absolute"
				left={0}
				bottom={0}
				top={0}
				min-width="100px"
				max-width="1190px"
				width="100%"
				background="linear-gradient(90deg, #0B0808 0%, rgba(11, 8, 8, 0) 100%)"
				display="block"
				sm-display="none"
			/>
			<Box
				position="absolute"
				right={0}
				bottom={0}
				top={0}
				left={0}
				height="88px"
				z-index="100"
				sm-height="52px"
			>
				<Box
					height="88px"
					flex-direction="row"
					justify-content="space-between"
					align-items="center"
					grid-gap="190px"
					display="flex"
					flex-wrap="no-wrap"
					sm-padding="0px 16px 0px 16px"
					sm-height="52px"
					sm-display="flex"
					sm-grid-column-gap="0px"
					sm-grid-row-gap="0px"
					grid-column-gap={0}
					md-padding="24px 16px 24px 16px"
					lg-padding="24px 16px 24px 16px"
					margin="0px auto 0px auto"
					max-width="1348px"
					position="relative"
				/>
			</Box>
		</Box>
		<Box
			min-width="100px"
			min-height="100px"
			background="linear-gradient(67.6deg, #0B0908 60.29%, #4A2528 113.2%)"
			position="relative"
			height="1020px"
			sm-padding="0px 16px 0px 16px"
			padding="0px 16px 0px 16px"
			sm-overflow="hidden"
			sm-height="788px"
		>
			<Box
				position="absolute"
				left={0}
				bottom={0}
				top={0}
				min-height="100px"
				min-width="100px"
				max-width="1190px"
				width="100%"
				background="linear-gradient(90deg, #0B0808 0%, rgba(11, 8, 8, 0) 100%)"
				display="block"
				sm-display="none"
			/>
			<Box
				position="absolute"
				left={0}
				bottom={0}
				top={0}
				min-height="100px"
				min-width="100px"
				max-width="1190px"
				width="100%"
				background="linear-gradient(90deg, #0B0808 0%, rgba(11, 8, 8, 0) 100%)"
				display="block"
				sm-display="none"
			/>
			<Box
				min-width="100px"
				width="1594px"
				overflow="visible"
				background="url(https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/untitled%201.webp?v=2024-10-03T21:15:17.374Z) 0% 0% /auto repeat scroll padding-box"
				min-height="100px"
				height="924px"
				position="absolute"
				top="auto"
				quarkly-title="untitled1"
				right="0px"
				sm-bottom={0}
				sm-width="150%"
				sm-background="url(https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/untitled%201.png?v=2024-08-14T13:48:42.280Z) 50% 100% /contain no-repeat scroll padding-box"
				sm-height="100vw"
				sm-top="auto"
				sm-transform="translateX(15%)"
				bottom="-60px"
				left="auto"
				z-index="0"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				position="absolute"
				left={0}
				top={0}
				width="100%"
				height="100%"
				mix-blend-mode="color-dodge"
				background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/Textures.webp?v=2024-10-03T21:37:55.029Z) 0% 0% /auto repeat scroll padding-box"
				quarkly-title="Texture"
				display="block"
				pointer-events="none"
				z-index="300"
			/>
			<Box
				align-self="stretch"
				height="502px"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				gap="180px"
				display="flex"
				sm-margin="79px 0px 0px 0px"
				max-width="1348px"
				margin="0px auto 0px auto"
				padding="150px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				{"    "}
				<Box
					align-self="stretch"
					flex-direction="column"
					align-items="flex-start"
					display="flex"
					grid-row-gap="56px"
					sm-height="auto"
					sm-display="flex"
					sm-grid-row-gap="32px"
					position="absolute"
					z-index="50"
					sm-position="static"
				>
					{"      "}
					<Box
						align-self="stretch"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						gap="28px"
						display="flex"
						position="relative"
						grid-row-gap="28px"
						sm-height="auto"
					>
						{"        "}
						<Text
							color="white"
							font=" 700 80px Epilogue"
							line-height="88px"
							word-wrap="break-word"
							sm-font="600 30px/34px Epilogue"
							sm-width="100%"
							margin="0px 0px 0px 0px"
							sm-height="auto"
							max-width="900px"
						>
							Pioneering the Future of Data Storage and Optical Innovation
						</Text>
						{"        "}
						<Text
							color="white"
							font=" 400 21px Epilogue"
							line-height="32px"
							word-wrap="break-word"
							sm-width="100%"
							margin="0px 0px 0px 0px"
							sm-height="auto"
							max-width="747px"
							sm-font="400 17px/26px Epilogue"
						>
							Discover our groundbreaking solutions in optical fabrication and ultra-long-term data preservation. SPhotonix is pioneering the future of precision and ultrafast nanostructuring innovation.
						</Text>
						{"      "}
					</Box>
					{"      "}
					<Box
						justify-content="flex-start"
						display="flex"
						align-items="flex-start"
						height="58px"
						sm-display="flex"
						sm-flex-direction="column"
						sm-flex-wrap="no-wrap"
						sm-width="100%"
						sm-height="auto"
						sm-align-items="flex-start"
						sm-justify-content="center"
						sm-grid-row-gap="16px"
						flex-direction="row"
						flex-wrap="no-wrap"
						grid-column-gap="20px"
					>
						{"                "}
						<Components.PopupVideo sm-width="100%">
							<Override
								slot="Button Open"
								background="#EB1E1E"
								border-radius="8px"
								justify-content="flex-start"
								align-items="flex-start"
								display="flex"
								padding-left="24px"
								padding-right="24px"
								padding-top="16px"
								padding-bottom="16px"
								position="relative"
								sm-width="100%"
								sm-justify-content="center"
								height="58px"
								width="180px"
								cursor="pointer"
							/>
							<Override
								slot="Button Text"
								font="600 17px Epilogue"
								color="white"
								line-height="26px"
								focus-box-shadow="none"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
								position="relative"
								top="-40px"
								text-align="center"
								cursor="pointer"
							>
								Watch our story
							</Override>
							<Override slot="Content" padding="0px 0px 0px 0px" />
							<Override slot="Button Open Icon" display="none" />
							<Override slot="Button Wrapper" height="58px" />
							<Override slot="Wrapper :open" width="80%" />
							<Override
								slot="Button Close"
								color="#ffffff"
								background="#000000"
								border-radius="24px"
								top="4px"
								right="4px"
							/>
							<Override slot="Overlay" cursor="pointer" />
							<Override slot="Button Backgound" sm-width="100%" />
							<Override slot="Popup" z-index="999999" />
							<Components.Stream />
						</Components.PopupVideo>
						{"      "}
					</Box>
					{"    "}
				</Box>
				{"  "}
			</Box>
		</Box>
		<Box
			padding-left="190px"
			padding-right="190px"
			padding-top="100px"
			padding-bottom="100px"
			background="url(https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/Rectangle%204439.png?v=2024-08-14T14:32:57.122Z) repeat,linear-gradient(0deg, #EEEEEE 0%, #EEEEEE 100%)"
			flex-direction="column"
			justify-content="flex-start"
			align-items="flex-start"
			gap="40px"
			display="inline-flex"
			sm-padding="60px 16px 60px 16px"
			padding="100px 16px 100px 16px"
			sm-max-width="100%"
			sm-height="auto"
			width="100%"
		>
			{"  "}
			<Box
				align-self="stretch"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				grid-gap="24px"
				display="flex"
				sm-max-width="100%"
				max-width="1348px"
				margin="0px auto 0px auto"
			>
				{"    "}
				<Box
					align-self="stretch"
					flex-direction="column"
					justify-content="flex-start"
					align-items="flex-start"
					grid-gap="40px"
					display="flex"
				>
					{"      "}
					<Box
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="24px"
						display="flex"
						sm-max-width="100%"
						sm-height="auto"
						sm-display="flex"
						sm-grid-row-gap="16px"
					>
						{"        "}
						<Text
							align-self="stretch"
							color="#F44450"
							font=" 500 16px Epilogue"
							text-transform="uppercase"
							line-height="20px"
							letter-spacing="0.80px"
							word-wrap="break-word"
							sm-margin="0px 0px 0px 0px"
							sm-font="500 13px/14px Epilogue"
							sm-letter-spacing="5%"
						>
							THE FUTURE IS NOW
						</Text>
						{"        "}
						<Box
							align-self="stretch"
							flex-direction="column"
							justify-content="flex-start"
							align-items="flex-start"
							gap="28px"
							display="flex"
							sm-display="flex"
							sm-grid-row-gap="16px"
						>
							{"          "}
							<Text
								align-self="stretch"
								color="#080808"
								font="normal 600 72px/100% Epilogue"
								line-height="76px"
								word-wrap="break-word"
								text-fill-color="transparent"
								style={{
									"-webkit-text-fill-color": "transparent"
								}}
								background="linear-gradient(91.48deg, #080808 -0.28%, #FF0C1D 97.27%) text"
								background-clip="text"
								margin="0px 0px 0px 0px"
								sm-font="normal 600 28px/32px Epilogue"
								sm-height="auto"
								text-align="left"
							>
								Enabling transformative new possibilities from data storage and imaging to microscopy, space and beyond.
							</Text>
							{"          "}
							<Text
								align-self="stretch"
								color="#212121"
								font=" 400 21px Epilogue"
								line-height="32px"
								word-wrap="break-word"
								max-width="550px"
								sm-font="400 17px/26px Epilogue"
								sm-margin="0px 0px 0px 0px"
								sm-height="auto"
								margin="30px 0px 0px 0px"
							>
								Developing the Future of Ultrafast nano-structuring          of transparent materials for Everyone.
							</Text>
							{"        "}
						</Box>
						{"      "}
					</Box>
					{"      "}
					<Box
						align-self="stretch"
						height="137px"
						flex-direction="column"
						justify-content="space-between"
						align-items="flex-start"
						gap="40px"
						display="flex"
						max-width="100%"
						sm-height="auto"
						sm-display="flex"
						sm-flex-direction="column"
						sm-flex-wrap="no-wrap"
						sm-align-items="center"
						sm-justify-content="center"
						sm-grid-row-gap="32px"
					>
						{"        "}
						<Box align-self="stretch" height="1px" background="#B1B1B1" />
						<Text
							color="#212121"
							font=" 400 21px Epilogue"
							line-height="32px"
							word-wrap="break-word"
							max-width="899px"
							margin="0px 0px 0px 0px"
							sm-height="auto"
							sm-font="400 17px/26px Epilogue"
						>
							Our products and services are built upon the unique proprietary tech that will power the next generation of data storage, developed at the world leading SPhotonix R&D facilities. Access the future today,{" "}
							<Link
								href="/about-us"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								learn more
							</Link>
							{" "}about what we offer.
						</Text>
						{"              "}
					</Box>
					{"    "}
				</Box>
				{"  "}
			</Box>
			{"  "}
			<Box
				align-self="stretch"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				gap="32px"
				display="flex"
				sm-height="auto"
				max-width="1348px"
				margin="0px auto 0px auto"
			>
				{"    "}
				<Box
					align-self="stretch"
					justify-content="flex-start"
					align-items="flex-start"
					grid-gap="2px"
					display="inline-flex"
					margin="40px 0px 0px 0px"
					lg-display="flex"
					lg-flex-direction="column"
					lg-flex-wrap="no-wrap"
				>
					{"      "}
					<Box
						flex="1 1 0"
						align-self="stretch"
						padding-top="40px"
						padding-bottom="32px"
						padding-left="32px"
						padding-right="32px"
						background="white"
						border="1.50px solid"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="32px"
						display="inline-flex"
						border-width={0}
						padding="40px 32px 50px 32px"
						sm-padding="24px 24px 24px 24px"
					>
						<Components.SvgIcon
							width="64px"
							height="64px"
							position="relative"
							svg="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/fi_1343124.svg?v=2024-08-15T12:09:38.418Z"
							background="#EB1E1E"
						/>
						{"        "}
						<Box
							align-self="stretch"
							flex-direction="column"
							justify-content="flex-start"
							align-items="flex-start"
							grid-gap="16px"
							display="flex"
							sm-height="auto"
						>
							{"          "}
							<Text
								align-self="stretch"
								color="#080808"
								font=" 600 28px/32px Epilogue"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
								sm-font="600 21px/28px Epilogue"
							>
								Data Archiving Solutions
							</Text>
							{"          "}
							<Text
								align-self="stretch"
								color="#212121"
								font="500 21px/26px Epilogue"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
								sm-height="auto"
								sm-font="500 15px/24px Epilogue"
							>
								Securely store any data for eternity, today.
							</Text>
							{"          "}
							<Text
								align-self="stretch"
								color="#212121"
								font="400 17px/26px Epilogue"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
								sm-font="400 15px/24px Epilogue"
							>
								Our Archival Data Solutions service enables anyone to take advantage of tomorrow's data storage technology today. Complete your order online for your quartz glass up to 10TB of data per 5 inch glass platter to be stored in our Swiss Vault facility, or have it securely delivered.
							</Text>
							{"        "}
						</Box>
						{"      "}
					</Box>
					{"      "}
					<Box
						flex="1 1 0"
						align-self="stretch"
						padding-top="40px"
						padding-bottom="32px"
						padding-left="32px"
						padding-right="32px"
						background="white"
						border="1.50px solid"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="32px"
						display="inline-flex"
						border-width={0}
						sm-height="auto"
						sm-padding="24px 24px 24px 24px"
					>
						{"        "}
						<Components.SvgIcon
							width="64px"
							height="64px"
							position="relative"
							svg="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/Optical%20Device%20Fabrication.svg?v=2024-08-15T11:53:31.778Z"
							background="#EB1E1E"
						/>
						{"        "}
						<Box
							align-self="stretch"
							flex-direction="column"
							justify-content="flex-start"
							align-items="flex-start"
							grid-gap="16px"
							display="flex"
							sm-height="auto"
						>
							{"          "}
							<Text
								align-self="stretch"
								color="#080808"
								font=" 600 28px Epilogue"
								line-height="32px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
								sm-font="600 21px/28px Epilogue"
							>
								Optical Device Fabrication
							</Text>
							{"          "}
							<Text
								align-self="stretch"
								color="#212121"
								font="500 21px/26px Epilogue"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
								sm-font="500 15px/24px Epilogue"
							>
								Bespoke nano-structuring of transparent materials
							</Text>
							{"                  "}
							<Text
								align-self="stretch"
								color="#212121"
								font=" 400 17px/26px Epilogue"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
								sm-font="400 15px/24px Epilogue"
							>
								Leveraging cutting-edge laboratory capabilities and proprietary technology, we are able to produce state-of-the-art optical devices including waveguides, lenses, and diffraction gratings, that enable new possibilities for a wide range of applications in imaging, microscopy, sensing and telecommunication.
							</Text>
						</Box>
						{"      "}
					</Box>
					{"      "}
					<Box
						flex="1 1 0"
						align-self="stretch"
						padding-top="40px"
						padding-bottom="32px"
						padding-left="32px"
						padding-right="32px"
						background="white"
						border="1.50px solid"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="32px"
						display="inline-flex"
						border-width={0}
						sm-height="auto"
						sm-padding="24px 24px 24px 24px"
					>
						{"        "}
						<Components.SvgIcon
							width="64px"
							height="64px"
							position="relative"
							svg="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/fi_1309187.svg?v=2024-08-15T12:09:38.418Z"
							background="#EB1E1E"
						/>
						{"        "}
						<Box
							align-self="stretch"
							flex-direction="column"
							justify-content="flex-start"
							align-items="flex-start"
							grid-gap="16px"
							display="flex"
							sm-height="auto"
						>
							{"          "}
							<Text
								align-self="stretch"
								color="#080808"
								font=" 600 28px Epilogue"
								line-height="32px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
								sm-font="600 21px/28px Epilogue"
							>
								Technology Licensing
							</Text>
							{"          "}
							<Text
								align-self="stretch"
								color="#212121"
								font="500 21px/32px Epilogue"
								line-height="26px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
								sm-font="500 15px/24px Epilogue"
							>
								Be at the forefront of solving your data storage limitations
							</Text>
							{"          "}
							<Text
								align-self="stretch"
								color="#212121"
								font=" 400 17px Epilogue"
								line-height="26px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
								sm-font="400 15px/24px Epilogue"
							>
								SPhotonix is revolutionizing data storage with cutting-edge laser nanostructuring technology for quartz glass, paving the way for the high density, ultra-durable systems of tomorrow, storing up to 360TB in a small glass platter for at least 1000 years. Partner with us to lead the future of data storage innovation
							</Text>
							{"        "}
						</Box>
						{"      "}
					</Box>
					{"    "}
				</Box>
				{"  "}
			</Box>
		</Box>
		<Box
			padding-top="140px"
			padding-bottom="120px"
			padding-left="190px"
			padding-right="190px"
			background-blend-mode="color-dodge, normal, normal"
			flex-direction="column"
			justify-content="center"
			align-items="center"
			grid-gap="64px"
			display="flex"
			width="100%"
			background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/technology-bg.webp?v=2024-10-03T21:04:17.534Z) 0% 0% /cover repeat scroll padding-box"
			sm-padding="60px 16px 60px 16px"
			sm-height="auto"
			flex-wrap="no-wrap"
			padding="140px 16px 120px 16px"
		>
			{"  "}
			<Box
				align-self="stretch"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				gap="24px"
				display="flex"
				sm-max-width="100%"
				sm-height="auto"
				max-width="1348px"
				margin="0px auto 0px auto"
				width="100%"
			>
				{"    "}
				<Components.Innovation />
				{"    "}
				<Box
					flex-direction="column"
					justify-content="flex-start"
					align-items="flex-start"
					grid-gap="28px"
					display="flex"
					sm-max-width="100%"
					sm-display="flex"
					sm-grid-row-gap="16px"
				>
					{"      "}
					<Text
						align-self="stretch"
						color="white"
						font="600 72px Epilogue"
						line-height="76px"
						word-wrap="break-word"
						sm-max-width="100%"
						sm-font="600 28px/32px Epilogue"
						sm-margin="0px 0px 0px 0px"
						margin="0px 0px 0px 0px"
					>
						Technology
					</Text>
					{"      "}
					<Text
						opacity={0.8}
						color="white"
						font="400 21px Epilogue"
						line-height="32px"
						word-wrap="break-word"
						sm-max-width="100%"
						sm-font="400 17px/26px Epilogue"
						sm-margin="0px 0px 0px 0px"
						margin="0px 0px 0px 0px"
						max-width="899px"
					>
						Explore our cutting-edge advancements in transparent material nanostructuring, storage systems and nano-scale imaging.
					</Text>
					{"    "}
				</Box>
				{"  "}
			</Box>
			{"  "}
			<Box
				align-self="stretch"
				height="auto"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				grid-gap="24px"
				display="flex"
				sm-max-width="100%"
				max-width="1348px"
				margin="0px auto 0px auto"
			>
				{"    "}
				<Box
					padding-left="64px"
					background="rgba(255, 255, 255, 0.90)"
					border="1.50px solid"
					justify-content="center"
					grid-gap="64px"
					display="flex"
					md-display="flex"
					md-flex-direction="column-reverse"
					md-flex-wrap="no-wrap"
					md-align-items="center"
					md-justify-content="center"
					md-padding="0px 0px 0px 0px"
					md-height="auto"
					md-grid-row-gap="0px"
					flex-direction="row"
					flex-wrap="no-wrap"
					align-items="center"
				>
					{"      "}
					<Box
						align-self="stretch"
						padding-top="64px"
						padding-bottom="56px"
						flex-direction="column"
						justify-content="space-between"
						align-items="flex-start"
						display="inline-flex"
						md-height="auto"
						md-padding="0px 0px 0px 0px"
						md-margin="32px 24px 24px 24px"
						flex="1 1 0%"
					>
						<Box
							align-self="stretch"
							flex-direction="column"
							justify-content="flex-start"
							align-items="flex-start"
							grid-gap="40px"
							display="flex"
							md-height="auto"
						>
							{"          "}
							<Box
								align-self="stretch"
								flex-direction="column"
								justify-content="flex-start"
								align-items="flex-start"
								grid-gap="32px"
								display="flex"
								md-height="auto"
							>
								{"            "}
								<Box
									align-self="stretch"
									flex-direction="column"
									justify-content="flex-start"
									align-items="flex-start"
									grid-gap="16px"
									display="flex"
									md-height="auto"
								>
									{"              "}
									<Text
										align-self="stretch"
										color="#080808"
										font=" 700 42px Epilogue"
										line-height="50px"
										word-wrap="break-word"
										margin="0px 0px 0px 0px"
										md-height="auto"
										md-font="600 21px/28px Epilogue"
									>
										State-of-the-art Facilities
									</Text>
									{"              "}
									<Text
										align-self="stretch"
										color="#212121"
										font=" 400 21px Epilogue"
										line-height="32px"
										word-wrap="break-word"
										margin="0px 0px 20px 0px"
										md-height="auto"
										md-font="400 15px/24px Epilogue"
									>
										Our pioneering laboratory is at the forefront of nanotechnology and photonics innovation, housing cutting-edge instruments essential for our core service and product offerings Our facilities are equipped with advanced femtosecond lasers and precision nano-fabrication equipment, allowing us to develop ultra-durable data storage solutions and fabricate unique optical components with nanometer precision. These capabilities ensure that we can push the boundaries of data storage and photonics while delivering reliable, high-performance solutions for our partners.{" "}
										<Link href="/about-us">
											Learn more...
										</Link>
									</Text>
									<Box
										align-self="stretch"
										flex-direction="column"
										justify-content="flex-end"
										align-items="center"
										grid-gap="24px"
										display="flex"
										md-display="none"
									>
										{"          "}
										<Box align-self="stretch" height="1px" background="#B1B1B1" />
										{"          "}
										<Text
											align-self="stretch"
											color="#212121"
											font=" 400 17px Epilogue"
											line-height="26px"
											word-wrap="break-word"
											margin="0px 0px 0px 0px"
											md-height="auto"
										>
											SPhotonix facilities enable our team to develop mainstream data storage technology of tomorrow, and service the product demand of our partners, today.
										</Text>
										{"        "}
									</Box>
									{"            "}
								</Box>
								{"          "}
							</Box>
							{"        "}
						</Box>
						{"                      "}
					</Box>
					{"      "}
					<Box
						display="flex"
						align-self="stretch"
						align-items="center"
						flex-direction="column"
						justify-content="center"
						flex-wrap="no-wrap"
						flex="1 1 0%"
					>
						<Image
							height="100%"
							background="linear-gradient(180deg, #D7D7D7 0%, #A0A0A0 100%)"
							src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/State-of-the-art%20Facilities.png?v=2024-09-20T15:01:53.799Z"
							md-max-width="100%"
							md-height="auto"
							md-width="auto"
							object-fit="contain"
							width="100%"
							object-position="50% 50%"
							align-self="stretch"
							srcSet="https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/State-of-the-art%20Facilities.png?v=2024-09-20T15%3A01%3A53.799Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/State-of-the-art%20Facilities.png?v=2024-09-20T15%3A01%3A53.799Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/State-of-the-art%20Facilities.png?v=2024-09-20T15%3A01%3A53.799Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/State-of-the-art%20Facilities.png?v=2024-09-20T15%3A01%3A53.799Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/State-of-the-art%20Facilities.png?v=2024-09-20T15%3A01%3A53.799Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/State-of-the-art%20Facilities.png?v=2024-09-20T15%3A01%3A53.799Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/State-of-the-art%20Facilities.png?v=2024-09-20T15%3A01%3A53.799Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
						/>
					</Box>
					{"    "}
				</Box>
				<Box
					padding-left="64px"
					background="rgba(255, 255, 255, 0.90)"
					border="1.50px solid"
					justify-content="center"
					grid-gap="64px"
					display="flex"
					md-display="flex"
					md-flex-direction="column-reverse"
					md-flex-wrap="no-wrap"
					md-align-items="center"
					md-justify-content="center"
					md-padding="0px 0px 0px 0px"
					md-height="auto"
					md-grid-row-gap="0px"
					flex-direction="row"
					flex-wrap="no-wrap"
					align-items="center"
				>
					{"      "}
					<Box
						align-self="stretch"
						padding-top="64px"
						padding-bottom="56px"
						flex-direction="column"
						justify-content="space-between"
						align-items="flex-start"
						display="inline-flex"
						md-height="auto"
						md-padding="0px 0px 0px 0px"
						md-margin="32px 24px 24px 24px"
						flex="1 1 0%"
					>
						<Box
							align-self="stretch"
							flex-direction="column"
							justify-content="flex-start"
							align-items="flex-start"
							grid-gap="40px"
							display="flex"
							md-height="auto"
						>
							{"          "}
							<Box
								align-self="stretch"
								flex-direction="column"
								justify-content="flex-start"
								align-items="flex-start"
								grid-gap="32px"
								display="flex"
								md-height="auto"
							>
								{"            "}
								<Box
									align-self="stretch"
									flex-direction="column"
									justify-content="flex-start"
									align-items="flex-start"
									grid-gap="16px"
									display="flex"
									md-height="auto"
								>
									{"              "}
									<Text
										align-self="stretch"
										color="#080808"
										font=" 700 42px Epilogue"
										line-height="50px"
										word-wrap="break-word"
										margin="0px 0px 0px 0px"
										md-height="auto"
										md-font="600 21px/28px Epilogue"
									>
										Proprietary ultrafast laser nano-structuring system
									</Text>
									{"              "}
									<Text
										align-self="stretch"
										color="#212121"
										font=" 400 21px Epilogue"
										line-height="32px"
										word-wrap="break-word"
										margin="0px 0px 20px 0px"
										md-height="auto"
										md-font="400 15px/24px Epilogue"
									>
										SPhotonix FemtoEtch™ is the result of over two decades of groundbreaking research, culminating in a patented system that empowers femtosecond laser writing with unparalleled precision. Our technology allows software-driven control over the polarization and birefringence of each individual voxel or nanograting in 3D transparent materials like glass, unlocking new frontiers in data storage, imaging, microscopy, sensing, and telecommunications and laser fusion.{" "}
										<Span
											overflow-wrap="normal"
											word-break="normal"
											white-space="normal"
											text-indent="0"
											text-overflow="clip"
											hyphens="manual"
											user-select="auto"
											pointer-events="auto"
										>
											<Link
												href="/optical-device-fabrication"
												overflow-wrap="normal"
												word-break="normal"
												white-space="normal"
												text-indent="0"
												text-overflow="clip"
												hyphens="manual"
												user-select="auto"
												pointer-events="auto"
											>
												Learn more..
											</Link>
										</Span>
										.
									</Text>
									<Box
										align-self="stretch"
										flex-direction="column"
										justify-content="flex-end"
										align-items="center"
										grid-gap="24px"
										display="flex"
										md-display="none"
									>
										{"          "}
										<Box align-self="stretch" height="1px" background="#B1B1B1" />
										{"          "}
										<Text
											align-self="stretch"
											color="#212121"
											font=" 400 17px Epilogue"
											line-height="26px"
											word-wrap="break-word"
											margin="0px 0px 0px 0px"
											md-height="auto"
										>
											SPhotonix proprietary capabilities enable rapid creation of trillions of nanograting or nanovoxel structures inside glass and other transparent materials. This ability enables unprecedented solutions and cost savings for our partners.
										</Text>
										{"        "}
									</Box>
									{"            "}
								</Box>
								{"          "}
							</Box>
							{"        "}
						</Box>
						{"                      "}
					</Box>
					{"      "}
					<Box
						display="flex"
						align-self="stretch"
						align-items="center"
						flex-direction="column"
						justify-content="center"
						flex-wrap="no-wrap"
						flex="1 1 0%"
					>
						<Image
							height="100%"
							background="linear-gradient(180deg, #D7D7D7 0%, #A0A0A0 100%)"
							src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/Proprietary%20ultrafast%20laser%20nano-structuring%20system.png?v=2024-09-20T14:53:54.857Z"
							md-max-width="100%"
							md-height="auto"
							md-width="auto"
							object-fit="contain"
							width="100%"
							object-position="50% 50%"
							align-self="stretch"
							srcSet="https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Proprietary%20ultrafast%20laser%20nano-structuring%20system.png?v=2024-09-20T14%3A53%3A54.857Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Proprietary%20ultrafast%20laser%20nano-structuring%20system.png?v=2024-09-20T14%3A53%3A54.857Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Proprietary%20ultrafast%20laser%20nano-structuring%20system.png?v=2024-09-20T14%3A53%3A54.857Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Proprietary%20ultrafast%20laser%20nano-structuring%20system.png?v=2024-09-20T14%3A53%3A54.857Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Proprietary%20ultrafast%20laser%20nano-structuring%20system.png?v=2024-09-20T14%3A53%3A54.857Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Proprietary%20ultrafast%20laser%20nano-structuring%20system.png?v=2024-09-20T14%3A53%3A54.857Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Proprietary%20ultrafast%20laser%20nano-structuring%20system.png?v=2024-09-20T14%3A53%3A54.857Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
						/>
					</Box>
					{"    "}
				</Box>
				<Box
					padding-left="64px"
					background="rgba(255, 255, 255, 0.90)"
					border="1.50px solid"
					justify-content="center"
					grid-gap="64px"
					display="flex"
					md-display="flex"
					md-flex-direction="column-reverse"
					md-flex-wrap="no-wrap"
					md-align-items="center"
					md-justify-content="center"
					md-padding="0px 0px 0px 0px"
					md-height="auto"
					md-grid-row-gap="0px"
					flex-direction="row"
					flex-wrap="no-wrap"
					align-items="center"
				>
					{"      "}
					<Box
						align-self="stretch"
						padding-top="64px"
						padding-bottom="56px"
						flex-direction="column"
						justify-content="space-between"
						align-items="flex-start"
						display="inline-flex"
						md-height="auto"
						md-padding="0px 0px 0px 0px"
						md-margin="32px 24px 24px 24px"
						flex="1 1 0%"
					>
						<Box
							align-self="stretch"
							flex-direction="column"
							justify-content="flex-start"
							align-items="flex-start"
							grid-gap="40px"
							display="flex"
							md-height="auto"
						>
							{"          "}
							<Box
								align-self="stretch"
								flex-direction="column"
								justify-content="flex-start"
								align-items="flex-start"
								grid-gap="32px"
								display="flex"
								md-height="auto"
							>
								{"            "}
								<Box
									align-self="stretch"
									flex-direction="column"
									justify-content="flex-start"
									align-items="flex-start"
									grid-gap="16px"
									display="flex"
									md-height="auto"
								>
									{"              "}
									<Text
										align-self="stretch"
										color="#080808"
										font=" 700 42px Epilogue"
										line-height="50px"
										word-wrap="break-word"
										margin="0px 0px 0px 0px"
										md-height="auto"
										md-font="600 21px/28px Epilogue"
									>
										Data Storage Innovation
									</Text>
									{"              "}
									<Text
										align-self="stretch"
										color="#212121"
										font=" 400 21px Epilogue"
										line-height="32px"
										word-wrap="break-word"
										margin="0px 0px 20px 0px"
										md-height="auto"
										md-font="400 15px/24px Epilogue"
									>
										We are revolutionizing data storage with our cutting-edge nanostructuring technologies, utilizing ultrafast lasers to unlock unprecedented capacity, durability, and speed. By harnessing these advanced methods, we are not only breaking the limits of traditional storage solutions but also ensuring long-term stability in even the harshest environments. Our innovations pave the way for next-generation data storage, offering a highly efficient and scalable solution for the world's growing data retention needs.{" "}
										<Link href="/data-storage-innovation">
											Learn more...
										</Link>
									</Text>
									<Box
										align-self="stretch"
										flex-direction="column"
										justify-content="flex-end"
										align-items="center"
										grid-gap="24px"
										display="flex"
										md-display="none"
									>
										{"          "}
										<Box align-self="stretch" height="1px" background="#B1B1B1" />
										{"          "}
										<Text
											align-self="stretch"
											color="#212121"
											font=" 400 17px Epilogue"
											line-height="26px"
											word-wrap="break-word"
											margin="0px 0px 0px 0px"
											md-height="auto"
										>
											Data is stored in three dimensional glass plates inside which nano-sized structures are created providing further two optical dimensions.
										</Text>
										{"        "}
									</Box>
									{"            "}
								</Box>
								{"          "}
							</Box>
							{"        "}
						</Box>
						{"                      "}
					</Box>
					{"      "}
					<Box
						display="flex"
						align-self="stretch"
						align-items="center"
						flex-direction="column"
						justify-content="center"
						flex-wrap="no-wrap"
						flex="1 1 0%"
					>
						<Image
							height="100%"
							background="linear-gradient(180deg, #D7D7D7 0%, #A0A0A0 100%)"
							src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/Data%20Storage%20Innovation.png?v=2024-09-20T14:59:41.600Z"
							md-max-width="100%"
							md-height="auto"
							md-width="auto"
							object-fit="contain"
							width="100%"
							object-position="50% 50%"
							align-self="stretch"
							srcSet="https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Data%20Storage%20Innovation.png?v=2024-09-20T14%3A59%3A41.600Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Data%20Storage%20Innovation.png?v=2024-09-20T14%3A59%3A41.600Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Data%20Storage%20Innovation.png?v=2024-09-20T14%3A59%3A41.600Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Data%20Storage%20Innovation.png?v=2024-09-20T14%3A59%3A41.600Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Data%20Storage%20Innovation.png?v=2024-09-20T14%3A59%3A41.600Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Data%20Storage%20Innovation.png?v=2024-09-20T14%3A59%3A41.600Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Data%20Storage%20Innovation.png?v=2024-09-20T14%3A59%3A41.600Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
						/>
					</Box>
					{"    "}
				</Box>
				{"          "}
			</Box>
		</Box>
		<Box
			padding-top="80px"
			padding-bottom="180px"
			padding-left="190px"
			padding-right="190px"
			flex-direction="column"
			justify-content="flex-start"
			align-items="center"
			grid-gap="64px"
			display="inline-flex"
			width="100%"
			background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/infographics-bg.webp?v=2024-10-03T21:04:52.510Z) 0% 0% /cover scroll border-box"
			sm-padding="80px 16px 60px 16px"
			sm-height="auto"
			padding="80px 16px 180px 16px"
			sm-display="flex"
			sm-grid-row-gap="32px"
		>
			{"  "}
			<Box
				align-self="stretch"
				height="auto"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				grid-gap="24px"
				display="flex"
				sm-max-width="100%"
				margin="0px auto 0px auto"
				max-width="1348px"
				width="100%"
			>
				{"    "}
				<Text
					align-self="stretch"
					color="#F44450"
					font=" 500 16px Epilogue"
					text-transform="uppercase"
					line-height="20px"
					letter-spacing="0.80px"
					word-wrap="break-word"
					sm-margin="0px 0px 0px 0px"
					sm-font="500 13px/14px Epilogue"
					sm-letter-spacing="5%"
				>
					in numbers
				</Text>
				<Text
					align-self="stretch"
					color="white"
					font="600 72px Epilogue"
					line-height="76px"
					word-wrap="break-word"
					sm-max-width="100%"
					sm-font="600 28px/32px Epilogue"
					sm-margin="0px 0px 0px 0px"
					margin="0px 0px 0px 0px"
				>
					Key Facts
				</Text>
				{"  "}
				<Text
					width="288px"
					opacity={0.5}
					color="white"
					font="400 17px Epilogue"
					line-height="26px"
					word-wrap="break-word"
					margin="0px 0px 0px 0px"
					sm-display="block"
					display="none"
					sm-width="100%"
				>
					Introducing our groundbreaking technology for ultrafast nanostructure writing in glass.
				</Text>
			</Box>
			{"  "}
			<Box
				align-self="stretch"
				justify-content="flex-start"
				align-items="flex-start"
				grid-gap="2px"
				display="inline-flex"
				lg-max-width="100%"
				lg-display="flex"
				lg-flex-direction="column"
				lg-flex-wrap="no-wrap"
				margin="0px auto 0px auto"
				max-width="1348px"
				width="100%"
			>
				{"    "}
				<Box
					align-self="stretch"
					padding-left="32px"
					padding-right="32px"
					padding-top="64px"
					padding-bottom="64px"
					background="rgba(255, 255, 255, 0.10)"
					border="1px rgba(255, 255, 255, 0.20) solid"
					flex-direction="column"
					justify-content="flex-start"
					align-items="center"
					grid-gap="64px"
					display="inline-flex"
					sm-max-width="100%"
					sm-height="auto"
					sm-display="flex"
					sm-grid-row-gap="32px"
					sm-padding="40px 24px 24px 24px"
					padding="64px 32px 64px 32px"
					flex="1 1 0%"
				>
					{"      "}
					<Image
						align-self="stretch"
						height="auto"
						src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/image%2022%201.png?v=2024-08-10T17:29:47.914Z"
						max-height="246.15px"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/image%2022%201.png?v=2024-08-10T17%3A29%3A47.914Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/image%2022%201.png?v=2024-08-10T17%3A29%3A47.914Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/image%2022%201.png?v=2024-08-10T17%3A29%3A47.914Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/image%2022%201.png?v=2024-08-10T17%3A29%3A47.914Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/image%2022%201.png?v=2024-08-10T17%3A29%3A47.914Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/image%2022%201.png?v=2024-08-10T17%3A29%3A47.914Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/image%2022%201.png?v=2024-08-10T17%3A29%3A47.914Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
					/>
					{"      "}
					<Box
						align-self="stretch"
						height="auto"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="16px"
						display="flex"
						sm-height="auto"
					>
						{"        "}
						<Text
							align-self="stretch"
							text-align="center"
							color="white"
							font="600 72px Epilogue"
							line-height="76px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
							sm-font="600 64px/69px Epilogue"
						>
							360TB
						</Text>
						{"        "}
						<Text
							align-self="stretch"
							opacity={0.8}
							text-align="center"
							color="white"
							font="400 21px Epilogue"
							line-height="32px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
							sm-height="auto"
							sm-font="400 15px/24px Epilogue"
						>
							SPhotonix technology, can preserve up to 360TB of data for over 1000 times longer than any other commercially available product on a 5 inch plate of glass.
						</Text>
						{"      "}
					</Box>
					{"    "}
				</Box>
				{"        "}
				<Box
					align-self="stretch"
					padding-left="32px"
					padding-right="32px"
					padding-top="64px"
					padding-bottom="64px"
					background="rgba(255, 255, 255, 0.10)"
					border="1px rgba(255, 255, 255, 0.20) solid"
					flex-direction="column"
					justify-content="flex-start"
					align-items="center"
					grid-gap="64px"
					display="inline-flex"
					sm-height="auto"
					sm-display="flex"
					sm-grid-row-gap="32px"
					sm-padding="40px 24px 24px 24px"
					padding="64px 32px 64px 32px"
					flex="1 1 0%"
				>
					{"      "}
					<Image
						align-self="stretch"
						height="auto"
						src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/20241118_SPhotonix_particles_v3.png?v=2024-11-21T08:14:42.001Z"
						sm-height="auto"
						max-height="246.15px"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/20241118_SPhotonix_particles_v3.png?v=2024-11-21T08%3A14%3A42.001Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/20241118_SPhotonix_particles_v3.png?v=2024-11-21T08%3A14%3A42.001Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/20241118_SPhotonix_particles_v3.png?v=2024-11-21T08%3A14%3A42.001Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/20241118_SPhotonix_particles_v3.png?v=2024-11-21T08%3A14%3A42.001Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/20241118_SPhotonix_particles_v3.png?v=2024-11-21T08%3A14%3A42.001Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/20241118_SPhotonix_particles_v3.png?v=2024-11-21T08%3A14%3A42.001Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/20241118_SPhotonix_particles_v3.png?v=2024-11-21T08%3A14%3A42.001Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
					/>
					{"      "}
					<Box
						align-self="stretch"
						height="auto"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="16px"
						display="flex"
						sm-height="auto"
					>
						{"        "}
						<Text
							align-self="stretch"
							text-align="center"
							color="white"
							font="600 72px Epilogue"
							line-height="76px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
							sm-font="600 64px/69px Epilogue"
						>
							20nm
						</Text>
						{"        "}
						<Text
							align-self="stretch"
							opacity={0.8}
							text-align="center"
							color="white"
							font="400 21px Epilogue"
							line-height="32px"
							word-wrap="break-word"
							sm-font="400 15px/24px Epilogue"
							margin="0px 0px 0px 0px"
						>
							Our proprietary, precision controlled ultrafast laser writing system is capable of creating trillions of bespoke structures inside transparent materials.
						</Text>
						{"      "}
					</Box>
					{"    "}
				</Box>
				<Box
					padding-left="32px"
					padding-right="32px"
					padding-top="64px"
					padding-bottom="64px"
					background="rgba(255, 255, 255, 0.10)"
					border="1px rgba(255, 255, 255, 0.20) solid"
					flex-direction="column"
					justify-content="center"
					align-items="center"
					grid-gap="64px"
					display="flex"
					sm-height="auto"
					sm-display="flex"
					sm-grid-row-gap="32px"
					sm-padding="40px 24px 24px 24px"
					padding="64px 32px 64px 32px"
					flex="1 1 0%"
				>
					{"      "}
					<Image
						height="auto"
						src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/20241115_SPhotonix_planes_v2.png?v=2024-11-21T08:14:31.939Z"
						sm-height="auto"
						max-height="246.15px"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/20241115_SPhotonix_planes_v2.png?v=2024-11-21T08%3A14%3A31.939Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/20241115_SPhotonix_planes_v2.png?v=2024-11-21T08%3A14%3A31.939Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/20241115_SPhotonix_planes_v2.png?v=2024-11-21T08%3A14%3A31.939Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/20241115_SPhotonix_planes_v2.png?v=2024-11-21T08%3A14%3A31.939Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/20241115_SPhotonix_planes_v2.png?v=2024-11-21T08%3A14%3A31.939Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/20241115_SPhotonix_planes_v2.png?v=2024-11-21T08%3A14%3A31.939Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/20241115_SPhotonix_planes_v2.png?v=2024-11-21T08%3A14%3A31.939Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
					/>
					{"      "}
					<Box
						align-self="stretch"
						height="auto"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="16px"
						display="flex"
						sm-height="auto"
					>
						{"        "}
						<Text
							text-align="center"
							color="white"
							font="600 72px Epilogue"
							line-height="76px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
							sm-font="600 64px/69px Epilogue"
							align-self="stretch"
						>
							5D
						</Text>
						{"        "}
						<Text
							opacity={0.8}
							text-align="center"
							color="white"
							font="400 21px Epilogue"
							line-height="32px"
							word-wrap="break-word"
							sm-font="400 15px/24px Epilogue"
							margin="0px 0px 0px 0px"
							align-self="stretch"
						>
							The revolutionary patented approach enables precise nano-structuring modifications across 5 dimensions, adding polarization and bifringence control atop material dimensions.
						</Text>
						{"      "}
					</Box>
					{"    "}
				</Box>
				{"  "}
			</Box>
		</Box>
		<Box
			background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/ecosystem-trust-bg.png?v=2024-08-17T12:26:45.621Z) 0% 0% /cover repeat scroll padding-box"
			flex-direction="column"
			justify-content="center"
			align-items="center"
			grid-gap="72px"
			display="none"
			min-width="100px"
			min-height="100px"
			width="100%"
			height="auto"
			sm-padding="60px 16px 60px 16px"
			padding="100px 16px 100px 16px"
			sm-display="none"
			sm-grid-row-gap="40px"
			quarkly-title="Ecosystem Of Trust"
		>
			<Box
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				grid-gap="28px"
				display="inline-flex"
				sm-width="100%"
				max-width="1348px"
				width="100%"
				margin="0px auto 0px auto"
			>
				{"  "}
				<Box
					align-self="stretch"
					flex-direction="column"
					justify-content="flex-start"
					align-items="flex-start"
					grid-gap="24px"
					display="flex"
				>
					{"    "}
					<Text
						color="#080808"
						font="500 16px Epilogue"
						text-transform="uppercase"
						line-height="18px"
						letter-spacing="0.80px"
						word-wrap="break-word"
						margin="0px 0px 0px 0px"
						sm-font="500 13px/14px Epilogue"
					>
						Key takeaway
					</Text>
					{"    "}
					<Text
						color="#080808"
						font="600 72px Epilogue"
						line-height="76px"
						word-wrap="break-word"
						padding="0px 0px 0px 0px"
						margin="0px 0px 0px 0px"
						text-fill-color="transparent"
						style={{
							"-webkit-text-fill-color": "transparent"
						}}
						background="linear-gradient(90.11deg, #080808 -1.14%, #FF0C1D 63.7%)"
						background-clip="text"
						sm-font="600 28px/32px Epilogue"
						sm-width="100%"
					>
						An Ecosystem of Trust
					</Text>
					{"  "}
				</Box>
				{"  "}
				<Text
					width="674px"
					opacity={0.8}
					color="#080808"
					font="400 21px Epilogue"
					line-height="32px"
					word-wrap="break-word"
					margin="0px 0px 0px 0px"
					sm-width="100%"
					sm-font="400 17px/26px Epilogue"
				>
					SPhotonix collaborates with industry leaders to drive innovation and trust. Our network of partners spans diverse sectors, enabling seamless integration of our cutting-edge technology into a wide range of applications. Together, we’re shaping the future of data storage and optical solutions.
				</Text>
			</Box>
			<Box
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				grid-gap="32px"
				display="inline-flex"
				md-width="100%"
				md-display="flex"
				md-flex-direction="column"
				md-flex-wrap="no-wrap"
				md-align-items="center"
				md-justify-content="center"
				md-height="auto"
				max-width="1348px"
				width="100%"
				margin="0px auto 0px auto"
			>
				{"  "}
				<Box
					align-self="stretch"
					flex-direction="column"
					justify-content="flex-start"
					align-items="flex-start"
					grid-gap="2px"
					display="flex"
					md-height="auto"
				>
					{"    "}
					<Box
						align-self="stretch"
						justify-content="flex-start"
						align-items="center"
						grid-gap="2px"
						display="inline-flex"
						md-display="flex"
						md-flex-direction="column"
						md-flex-wrap="no-wrap"
						md-align-items="center"
						md-justify-content="center"
					>
						{"      "}
						<Box
							flex="1 1 0"
							align-self="stretch"
							padding="32px"
							background="rgba(255, 255, 255, 0.80)"
							border="1.50px solid"
							flex-direction="column"
							justify-content="flex-start"
							align-items="flex-start"
							grid-gap="64px"
							display="inline-flex"
							md-height="auto"
							border-color="rgba(0, 0, 0, 0)"
						>
							{"        "}
							<Box
								align-self="stretch"
								flex-direction="column"
								justify-content="flex-start"
								align-items="flex-start"
								grid-gap="80px"
								display="flex"
								md-height="auto"
								md-display="flex"
								md-grid-row-gap="24px"
							>
								{"                    "}
								<Image src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/microsoft.svg?v=2024-08-17T12:43:18.543Z" display="block" max-width="63px" width="100%" />
								<Box
									align-self="stretch"
									flex-direction="column"
									justify-content="flex-start"
									align-items="flex-start"
									grid-gap="16px"
									display="flex"
									md-height="auto"
								>
									{"            "}
									<Text
										align-self="stretch"
										color="#080808"
										font="600 28px Epilogue"
										line-height="32px"
										word-wrap="break-word"
										padding="unset"
										margin="0px 0px 0px 0px"
										md-font="600 21px/28px Epilogue"
									>
										Microsoft
									</Text>
									{"            "}
									<Text
										align-self="stretch"
										opacity={0.7}
										color="#212121"
										font="400 17px Epilogue"
										line-height="26px"
										word-wrap="break-word"
										padding="unset"
										margin="0px 0px 0px 0px"
										md-font="400 15px/24px Epilogue"
									>
										Microsoft Project Silica stems from decades of research and innovation from the founders of SPhotonix
									</Text>
									{"          "}
								</Box>
								{"        "}
							</Box>
							{"      "}
						</Box>
						{"      "}
						<Box
							flex="1 1 0"
							align-self="stretch"
							padding="32px"
							background="rgba(255, 255, 255, 0.80)"
							border="1.50px solid"
							flex-direction="column"
							justify-content="flex-start"
							align-items="flex-start"
							grid-gap="64px"
							display="inline-flex"
							md-display="flex"
							md-grid-row-gap="24px"
							border-color="rgba(0, 0, 0, 0)"
						>
							{"        "}
							<Image src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/space-x.svg?v=2024-08-17T12:39:22.036Z" display="block" max-width="274px" width="100%" />
							<Box
								align-self="stretch"
								flex-direction="column"
								justify-content="flex-start"
								align-items="flex-start"
								grid-gap="80px"
								display="flex"
							>
								{"                    "}
								<Box
									align-self="stretch"
									flex-direction="column"
									justify-content="flex-start"
									align-items="flex-start"
									grid-gap="16px"
									display="flex"
									md-height="auto"
								>
									{"            "}
									<Text
										align-self="stretch"
										color="#080808"
										font="600 28px Epilogue"
										line-height="32px"
										word-wrap="break-word"
										padding="unset"
										margin="0px 0px 0px 0px"
										md-font="600 21px/28px Epilogue"
									>
										Space X
									</Text>
									{"            "}
									<Text
										align-self="stretch"
										opacity={0.7}
										color="#212121"
										font="400 17px Epilogue"
										line-height="26px"
										word-wrap="break-word"
										padding="unset"
										margin="0px 0px 0px 0px"
										md-font="400 15px/24px Epilogue"
									>
										Valuable data written by SPhotonix's founding team was sent into Mars orbit aboard a Tesla Roadster boosted to space by the Falcon Heavy rocket
									</Text>
									{"          "}
								</Box>
								{"        "}
							</Box>
							{"      "}
						</Box>
						{"      "}
						<Box
							flex="1 1 0"
							align-self="stretch"
							padding="32px"
							background="rgba(255, 255, 255, 0.80)"
							border="1.50px solid"
							flex-direction="column"
							justify-content="flex-start"
							align-items="flex-start"
							grid-gap="64px"
							display="inline-flex"
							border-color="rgba(0, 0, 0, 0)"
						>
							{"        "}
							<Box
								align-self="stretch"
								flex-direction="column"
								justify-content="flex-start"
								align-items="flex-start"
								grid-gap="80px"
								display="flex"
								md-height="auto"
								md-display="flex"
								md-grid-row-gap="24px"
							>
								{"                    "}
								<Image src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/zeiss.svg?v=2024-08-17T12:44:19.221Z" display="block" max-width="63px" width="100%" />
								<Box
									align-self="stretch"
									flex-direction="column"
									justify-content="flex-start"
									align-items="flex-start"
									grid-gap="16px"
									display="flex"
									md-height="auto"
								>
									{"            "}
									<Text
										align-self="stretch"
										color="#080808"
										font="600 28px Epilogue"
										line-height="32px"
										word-wrap="break-word"
										padding="unset"
										margin="0px 0px 0px 0px"
										md-font="600 21px/28px Epilogue"
									>
										Zeiss
									</Text>
									{"            "}
									<Text
										align-self="stretch"
										opacity={0.7}
										color="#212121"
										font="400 17px Epilogue"
										line-height="26px"
										word-wrap="break-word"
										padding="unset"
										margin="0px 0px 0px 0px"
										md-font="400 15px/24px Epilogue"
									>
										Supplier of high-tech ZEISS equipment and integrated ZEISS Connecting Solutions for science, education and healthcare across the globe
									</Text>
									{"          "}
								</Box>
								{"        "}
							</Box>
							{"      "}
						</Box>
						{"      "}
						<Box
							flex="1 1 0"
							align-self="stretch"
							padding="32px"
							background="rgba(255, 255, 255, 0.80)"
							border="1.50px solid"
							flex-direction="column"
							justify-content="flex-start"
							align-items="flex-start"
							grid-gap="64px"
							display="inline-flex"
							border-color="rgba(0, 0, 0, 0)"
						>
							{"        "}
							<Box
								align-self="stretch"
								flex-direction="column"
								justify-content="flex-start"
								align-items="flex-start"
								grid-gap="80px"
								display="flex"
								md-height="auto"
								md-display="flex"
								md-grid-row-gap="24px"
							>
								{"                    "}
								<Image src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/kering.svg?v=2024-08-17T12:46:11.757Z" display="block" max-width="166.574px" width="100%" />
								<Box
									align-self="stretch"
									flex-direction="column"
									justify-content="flex-start"
									align-items="flex-start"
									grid-gap="16px"
									display="flex"
									md-height="auto"
								>
									{"            "}
									<Text
										align-self="stretch"
										color="#080808"
										font="600 28px Epilogue"
										line-height="32px"
										word-wrap="break-word"
										padding="unset"
										margin="0px 0px 0px 0px"
										md-font="600 21px/28px Epilogue"
									>
										Kering
									</Text>
									{"            "}
									<Text
										align-self="stretch"
										opacity={0.7}
										color="#212121"
										font="400 17px Epilogue"
										line-height="26px"
										word-wrap="break-word"
										padding="unset"
										margin="0px 0px 0px 0px"
										md-font="400 15px/24px Epilogue"
									>
										Bespoke jewellery masterpieces created with data written for eternity inside precious material
									</Text>
									{"          "}
								</Box>
								{"        "}
							</Box>
							{"      "}
						</Box>
						{"    "}
					</Box>
					{"  "}
				</Box>
			</Box>
		</Box>
		<Box
			width="100%"
			height="1263px"
			padding-left="190px"
			padding-right="190px"
			padding-top="132px"
			padding-bottom="132px"
			background="url(https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/blogs-bg.png?v=2024-08-18T10:10:26.882Z) 0% 0%/cover scroll"
			flex-direction="column"
			justify-content="flex-start"
			align-items="center"
			grid-gap="72px"
			sm-padding="60px 16px 60px 16px"
			sm-height="auto"
			padding="132px 190px 132px 190px"
			display="none"
		>
			<Box
				width="1348px"
				height="210px"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				grid-gap="64px"
				display="inline-flex"
				sm-width="100%"
			>
				{"  "}
				<Box
					align-self="stretch"
					justify-content="flex-start"
					align-items="flex-start"
					grid-gap="160px"
					display="inline-flex"
				>
					{"    "}
					<Box
						flex="1 1 0"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="24px"
						display="inline-flex"
						sm-width="100%"
					>
						{"      "}
						<Text
							color="#F44450"
							font="500 16px Epilogue"
							text-transform="uppercase"
							line-height="18px"
							letter-spacing="0.80px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
							sm-display="none"
						>
							Key takeaway
						</Text>
						{"      "}
						<Box
							align-self="stretch"
							height="168px"
							flex-direction="column"
							justify-content="flex-start"
							align-items="flex-start"
							grid-gap="28px"
							display="flex"
							sm-width="100%"
							sm-height="auto"
						>
							{"        "}
							<Text
								width="789px"
								color="white"
								font="600 72px Epilogue"
								line-height="76px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
								sm-font="600 28px/32px Epilogue"
								sm-width="100%"
							>
								Blogs
							</Text>
							{"        "}
							<Box
								align-self="stretch"
								justify-content="flex-start"
								align-items="flex-end"
								grid-gap="28px"
								display="inline-flex"
							>
								{"                    "}
								<Text
									flex="1 1 0"
									opacity={0.5}
									color="white"
									font="400 21px Epilogue"
									line-height="32px"
									word-wrap="break-word"
									margin="0px 0px 0px 0px"
									sm-width="100%"
								>
									SPhotonix team and community bring insights          and opinions from the world’s opto-electronic ecosystem.
								</Text>
								<Box
									justify-content="center"
									align-items="flex-start"
									grid-gap="16px"
									display="flex"
									sm-display="none"
								>
									{"                        "}
									<Box
										padding-left="24px"
										padding-right="24px"
										padding-top="22px"
										padding-bottom="22px"
										transform-origin="0 0"
										opacity={0.4}
										border-radius="80px"
										border="2px white solid"
										justify-content="flex-start"
										align-items="flex-start"
										grid-gap="10px"
										display="flex"
									>
										<Image src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/arrow.svg?v=2024-08-18T10:15:04.732Z" display="block" />
										{"                          "}
									</Box>
									<Box
										padding-left="24px"
										padding-right="24px"
										padding-top="22px"
										padding-bottom="22px"
										border-radius="80px"
										border="2px white solid"
										justify-content="flex-start"
										align-items="flex-start"
										grid-gap="10px"
										display="flex"
									>
										<Image src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/arrow.svg?v=2024-08-18T10:15:04.732Z" display="block" transform="rotate(180deg)" />
									</Box>
									{"          "}
								</Box>
								{"        "}
							</Box>
							{"      "}
						</Box>
						{"    "}
					</Box>
					{"  "}
				</Box>
			</Box>
			<Box
				width="1348px"
				height="717px"
				justify-content="flex-start"
				align-items="center"
				grid-gap="2px"
				display="flex"
				sm-width="100%"
				sm-display="flex"
				sm-flex-direction="column"
				sm-flex-wrap="no-wrap"
				sm-height="auto"
				flex-direction="row"
				flex-wrap="no-wrap"
			>
				{"      "}
				<Box
					align-self="stretch"
					padding="32px"
					background="rgba(255, 255, 255, 0.90)"
					border="1.50px white solid"
					flex-direction="column"
					justify-content="flex-start"
					align-items="flex-start"
					grid-gap="24px"
					display="inline-flex"
					sm-padding="8px 8px 24px 8px"
					max-width="33.33%"
					sm-max-width="initial"
				>
					{"    "}
					<Box
						align-self="stretch"
						height="586px"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="32px"
						display="flex"
						sm-height="auto"
					>
						{"      "}
						<Image
							align-self="stretch"
							height="312px"
							src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/Data%20Storage%20Innovation.png?v=2024-09-20T14:59:41.600Z"
							object-fit="cover"
							sm-max-height="180px"
							srcSet="https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Data%20Storage%20Innovation.png?v=2024-09-20T14%3A59%3A41.600Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Data%20Storage%20Innovation.png?v=2024-09-20T14%3A59%3A41.600Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Data%20Storage%20Innovation.png?v=2024-09-20T14%3A59%3A41.600Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Data%20Storage%20Innovation.png?v=2024-09-20T14%3A59%3A41.600Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Data%20Storage%20Innovation.png?v=2024-09-20T14%3A59%3A41.600Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Data%20Storage%20Innovation.png?v=2024-09-20T14%3A59%3A41.600Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Data%20Storage%20Innovation.png?v=2024-09-20T14%3A59%3A41.600Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
						/>
						{"      "}
						<Box
							align-self="stretch"
							height="242px"
							flex-direction="column"
							justify-content="flex-start"
							align-items="flex-start"
							grid-gap="16px"
							display="flex"
							sm-padding="0px 16px 0px 16px"
							sm-height="auto"
						>
							{"        "}
							<Text
								color="#959595"
								font="500 16px Epilogue"
								text-transform="uppercase"
								line-height="18px"
								letter-spacing="0.80px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
								sm-display="block"
								display="none"
							>
								Jun 18, 2024
							</Text>
							<Text
								align-self="stretch"
								color="#080808"
								font="600 28px Epilogue"
								line-height="32px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
							>
								Cloud-Scale Archival Storage Using Ultrafast Laser Nanostructuring
							</Text>
							{"        "}
							<Text
								align-self="stretch"
								color="#212121"
								font="400 17px Epilogue"
								line-height="26px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
							>
								Recent progress in ultrafast laser nanostructuring enables high-density multidimensional volumetric data writing. With exceptional media longevity, this could transform archival cloud storage.
							</Text>
							{"      "}
						</Box>
						{"    "}
					</Box>
					{"    "}
					<Box
						align-self="stretch"
						height="43px"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="24px"
						display="flex"
						sm-display="none"
					>
						{"      "}
						<Box align-self="stretch" height="1px" background="#B1B1B1" />
						{"      "}
						<Box align-self="stretch" justify-content="space-between" align-items="center" display="inline-flex">
							{"        "}
							<Text
								color="#959595"
								font="500 16px Epilogue"
								text-transform="uppercase"
								line-height="18px"
								letter-spacing="0.80px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
							>
								Jun 18, 2024
							</Text>
							{"        "}
							<Text
								color="#959595"
								font="500 16px Epilogue"
								text-transform="uppercase"
								line-height="18px"
								letter-spacing="0.80px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
							>
								Share
							</Text>
							{"      "}
						</Box>
						{"    "}
					</Box>
					{"  "}
				</Box>
				<Box
					align-self="stretch"
					padding="32px"
					background="rgba(255, 255, 255, 0.90)"
					border="1.50px white solid"
					flex-direction="column"
					justify-content="flex-start"
					align-items="flex-start"
					grid-gap="24px"
					display="inline-flex"
					sm-padding="8px 8px 24px 8px"
					flex="1 1 30%"
					max-width="33.33%"
					sm-max-width="initial"
				>
					{"    "}
					<Box
						align-self="stretch"
						height="586px"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="32px"
						display="flex"
						sm-height="auto"
					>
						{"      "}
						<Image
							align-self="stretch"
							height="312px"
							src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/Data%20Storage%20Innovation.png?v=2024-09-20T14:59:41.600Z"
							object-fit="cover"
							sm-max-height="180px"
							srcSet="https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Data%20Storage%20Innovation.png?v=2024-09-20T14%3A59%3A41.600Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Data%20Storage%20Innovation.png?v=2024-09-20T14%3A59%3A41.600Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Data%20Storage%20Innovation.png?v=2024-09-20T14%3A59%3A41.600Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Data%20Storage%20Innovation.png?v=2024-09-20T14%3A59%3A41.600Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Data%20Storage%20Innovation.png?v=2024-09-20T14%3A59%3A41.600Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Data%20Storage%20Innovation.png?v=2024-09-20T14%3A59%3A41.600Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Data%20Storage%20Innovation.png?v=2024-09-20T14%3A59%3A41.600Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
						/>
						{"      "}
						<Box
							align-self="stretch"
							height="242px"
							flex-direction="column"
							justify-content="flex-start"
							align-items="flex-start"
							grid-gap="16px"
							display="flex"
							sm-padding="0px 16px 0px 16px"
							sm-height="auto"
						>
							{"        "}
							<Text
								color="#959595"
								font="500 16px Epilogue"
								text-transform="uppercase"
								line-height="18px"
								letter-spacing="0.80px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
								sm-display="block"
								display="none"
							>
								Jun 18, 2024
							</Text>
							<Text
								align-self="stretch"
								color="#080808"
								font="600 28px Epilogue"
								line-height="32px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
							>
								The tiny glass blocks that can preserve your data for centuries
							</Text>
							{"        "}
							<Text
								align-self="stretch"
								color="#212121"
								font="400 17px Epilogue"
								line-height="26px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
							>
								For years governments, hospitals and families have had to use frail magnetic storage for their most important data. Now, scientists have an alternative — that lasts for ever.
							</Text>
							{"      "}
						</Box>
						{"    "}
					</Box>
					{"    "}
					<Box
						align-self="stretch"
						height="43px"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="24px"
						display="flex"
						sm-display="none"
					>
						{"      "}
						<Box align-self="stretch" height="1px" background="#B1B1B1" />
						{"      "}
						<Box align-self="stretch" justify-content="space-between" align-items="center" display="inline-flex">
							{"        "}
							<Text
								color="#959595"
								font="500 16px Epilogue"
								text-transform="uppercase"
								line-height="18px"
								letter-spacing="0.80px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
							>
								Jun 18, 2024
							</Text>
							{"        "}
							<Text
								color="#959595"
								font="500 16px Epilogue"
								text-transform="uppercase"
								line-height="18px"
								letter-spacing="0.80px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
							>
								Share
							</Text>
							{"      "}
						</Box>
						{"    "}
					</Box>
					{"  "}
				</Box>
				<Box
					align-self="stretch"
					padding="32px"
					background="rgba(255, 255, 255, 0.90)"
					border="1.50px white solid"
					flex-direction="column"
					justify-content="flex-start"
					align-items="flex-start"
					grid-gap="24px"
					display="inline-flex"
					sm-padding="8px 8px 24px 8px"
					flex="1 1 30%"
					max-width="33.33%"
					sm-max-width="initial"
				>
					{"    "}
					<Box
						align-self="stretch"
						height="586px"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="32px"
						display="flex"
						sm-height="auto"
					>
						{"      "}
						<Image
							align-self="stretch"
							height="312px"
							src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/Data%20Storage%20Innovation.png?v=2024-09-20T14:59:41.600Z"
							object-fit="cover"
							sm-max-height="180px"
							srcSet="https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Data%20Storage%20Innovation.png?v=2024-09-20T14%3A59%3A41.600Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Data%20Storage%20Innovation.png?v=2024-09-20T14%3A59%3A41.600Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Data%20Storage%20Innovation.png?v=2024-09-20T14%3A59%3A41.600Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Data%20Storage%20Innovation.png?v=2024-09-20T14%3A59%3A41.600Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Data%20Storage%20Innovation.png?v=2024-09-20T14%3A59%3A41.600Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Data%20Storage%20Innovation.png?v=2024-09-20T14%3A59%3A41.600Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b79a6ce778710018d27b85/images/Data%20Storage%20Innovation.png?v=2024-09-20T14%3A59%3A41.600Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
						/>
						{"      "}
						<Box
							align-self="stretch"
							height="242px"
							flex-direction="column"
							justify-content="flex-start"
							align-items="flex-start"
							grid-gap="16px"
							display="flex"
							sm-padding="0px 16px 0px 16px"
							sm-height="auto"
						>
							{"        "}
							<Text
								color="#959595"
								font="500 16px Epilogue"
								text-transform="uppercase"
								line-height="18px"
								letter-spacing="0.80px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
								sm-display="block"
								display="none"
							>
								Jun 18, 2024
							</Text>
							<Text
								align-self="stretch"
								color="#080808"
								font="600 28px Epilogue"
								line-height="32px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
							>
								Cloud-Scale Archival Storage Using Ultrafast Laser Nanostructuring
							</Text>
							{"        "}
							<Text
								align-self="stretch"
								color="#212121"
								font="400 17px Epilogue"
								line-height="26px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
							>
								Recent progress in ultrafast laser nanostructuring enables high-density multidimensional volumetric data writing. With exceptional media longevity, this could transform archival cloud storage.
							</Text>
							{"      "}
						</Box>
						{"    "}
					</Box>
					{"    "}
					<Box
						align-self="stretch"
						height="43px"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="24px"
						display="flex"
						sm-display="none"
					>
						{"      "}
						<Box align-self="stretch" height="1px" background="#B1B1B1" />
						{"      "}
						<Box align-self="stretch" justify-content="space-between" align-items="center" display="inline-flex">
							{"        "}
							<Text
								color="#959595"
								font="500 16px Epilogue"
								text-transform="uppercase"
								line-height="18px"
								letter-spacing="0.80px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
							>
								Jun 18, 2024
							</Text>
							{"        "}
							<Text
								color="#959595"
								font="500 16px Epilogue"
								text-transform="uppercase"
								line-height="18px"
								letter-spacing="0.80px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
							>
								Share
							</Text>
							{"      "}
						</Box>
						{"    "}
					</Box>
					{"  "}
				</Box>
			</Box>
		</Box>
		<Box
			width="100%"
			flex-direction="column"
			justify-content="flex-start"
			align-items="flex-start"
			grid-gap="140px"
			display="inline-flex"
			md-display="flex"
			md-padding="56px 16px 24px 16px"
			md-height="min-content"
			md-grid-row-gap="64px"
			padding="100px 16px 24px 16px"
			background="white"
		>
			{"    "}
			<Box
				align-self="stretch"
				justify-content="flex-start"
				align-items="flex-start"
				grid-gap="80px"
				display="inline-flex"
				md-display="flex"
				md-flex-direction="column"
				md-flex-wrap="no-wrap"
				md-align-items="center"
				md-justify-content="center"
				md-grid-row-gap="24px"
				max-width="1348px"
				margin="0px auto 0px auto"
				width="100%"
			>
				{"    "}
				<Box
					flex="1 1 0"
					flex-direction="column"
					justify-content="flex-start"
					align-items="flex-start"
					grid-gap="28px"
					display="inline-flex"
					md-width="100%"
					md-padding="0px 0px 0px 0px"
					md-height="fit-content"
					md-align-items="center"
					md-display="flex"
					md-flex-direction="column"
					md-justify-content="center"
					md-flex-wrap="no-wrap"
					md-grid-column-gap="0px"
					md-grid-row-gap="8px"
					md-max-width="460px"
				>
					{"      "}
					<Box
						align-self="stretch"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="24px"
						display="flex"
					>
						{"        "}
						<Text
							color="#080808"
							font="600 72px Epilogue"
							line-height="76px"
							word-wrap="break-word"
							margin="0px"
							md-font="600 28px/32px Epilogue"
							md-width="100%"
							text-fill-color="transparent"
							style={{
								"-webkit-text-fill-color": "transparent"
							}}
							background="linear-gradient(96.04deg, #080808 -0.01%, #FC0D1D 42.34%)"
							background-clip="text"
							max-width="100%"
							width="350px"
						>
							Get in touch...
						</Text>
						{"      "}
					</Box>
					{"      "}
					<Text
						align-self="stretch"
						opacity={0.8}
						color="#080808"
						font="400 21px Epilogue"
						line-height="32px"
						word-wrap="break-word"
						margin="0px 0px 0px 0px"
						md-width="100%"
						md-font="400 17px/26px Epilogue"
					>
						<Strong>
							<Link href="mailto:info@sphotonix.com?Web Enquiry: ">
								info@sphotonix.com
							</Link>
						</Strong>
					</Text>
					{"    "}
				</Box>
				{"    "}
				<Components.MakeForm
					md-width="100%"
					max-width="460px"
					sm-max-width="100%"
					action="https://hook.us2.make.com/vmpi9qw5hahp757juwr9sws9xf0n4k2u"
					method="post"
				>
					<Override
						slot="Form"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="16px"
						display="inline-flex"
					/>
					<Override
						slot="Content"
						display="flex"
						flex-direction="column"
						flex-wrap="no-wrap"
						grid-row-gap="16px"
					/>
					{"      "}
					<Text
						align-self="stretch"
						color="#212121"
						font="400 17px Epilogue"
						line-height="26px"
						word-wrap="break-word"
						margin="0px 0px 0px 0px"
						md-font="400 17px/26px Epilogue"
					>
						Stay ahead with the latest breakthroughs in optical nanotechnology! Discover how SPhotonix is revolutionizing data storage and optical innovation. Join our community for exclusive insights and updates!
					</Text>
					{"      "}
					<Components.MfInput name="name" type="text" width="100%">
						<Override
							slot="Input"
							display="inline-flex"
							placeholder-opacity={0.4}
							placeholder-color="#080808"
							placeholder-font="400 17px Epilogue"
							placeholder-line-height="26px"
							placeholder-word-wrap="break-word"
							width="100%"
							padding-left="24px"
							padding-right="24px"
							padding-top="16px"
							padding-bottom="16px"
							border-radius="8px"
							overflow="hidden"
							justify-content="flex-start"
							align-items="flex-start"
							grid-gap="10px"
							md-width="100%"
							placeholder="Name"
							border-color="#B1B1B1"
							border-width="1px"
							border-style="solid"
							focus-border-color=" #B1B1B1"
							background="none"
						/>
					</Components.MfInput>
					<Components.MfInput name="email" type="email" width="100%">
						<Override
							slot="Input"
							display="inline-flex"
							placeholder-opacity={0.4}
							placeholder-color="#080808"
							placeholder-font="400 17px Epilogue"
							placeholder-line-height="26px"
							placeholder-word-wrap="break-word"
							width="100%"
							padding-left="24px"
							padding-right="24px"
							padding-top="16px"
							padding-bottom="16px"
							border-radius="8px"
							overflow="hidden"
							justify-content="flex-start"
							align-items="flex-start"
							grid-gap="10px"
							md-width="100%"
							placeholder="Email"
							border-color="#B1B1B1"
							border-width="1px"
							border-style="solid"
							focus-border-color=" #B1B1B1"
							background="none"
						/>
					</Components.MfInput>
					{"      "}
					<Button
						width="100%"
						padding-left="24px"
						padding-right="24px"
						padding-top="16px"
						padding-bottom="16px"
						background="#EB1E1E"
						border-radius="8px"
						grid-gap="10px"
						color="white"
						font="600 17px Epilogue"
						line-height="26px"
						word-wrap="break-word"
						text-align="center"
						type="submit"
					>
						Subscribe to Newsletter
					</Button>
					{"    "}
				</Components.MakeForm>
				{"  "}
			</Box>
			{"  "}
			<Box
				align-self="stretch"
				height="51px"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				grid-gap="24px"
				display="flex"
				md-display="flex"
				md-grid-row-gap="32px"
				md-height="auto"
				max-width="1348px"
				margin="0px auto 0px auto"
				width="100%"
			>
				{"    "}
				<Box
					align-self="stretch"
					height="1px"
					background="#B1B1B1"
					md-width="100%"
					md-height="1px"
				/>
				{"    "}
				<Box
					align-self="stretch"
					justify-content="space-between"
					align-items="center"
					grid-gap="32px"
					display="flex"
					md-width="100%"
					md-height="fit-content"
					md-display="flex"
					md-flex-direction="row"
					md-flex-wrap="wrap"
					md-align-content="space-around"
					md-align-items="center"
					md-justify-content="space-between"
					flex-direction="row"
					md-grid-row-gap="16px"
				>
					{"      "}
					<Text
						color="#212121"
						font="400 17px Epilogue"
						line-height="26px"
						word-wrap="break-word"
						margin="0px 0px 0px 0px"
					>
						©2024 SPhotonix. All rights reserved.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						flex-direction="row"
						flex-wrap="no-wrap"
						align-items="center"
						justify-content="center"
						grid-column-gap="32px"
						md-order="-1"
					>
						<Text
							color="#212121"
							font="400 17px Epilogue"
							line-height="26px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
						>
							<Link href="/privacy-policy" color="inherit" text-decoration-line="initial">
								Privacy Policy
							</Link>
						</Text>
						<Text
							color="#212121"
							font="400 17px Epilogue"
							line-height="26px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
						>
							Cookies
						</Text>
					</Box>
					{"                "}
				</Box>
				{"  "}
			</Box>
		</Box>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66b79a6ce778710018d27b83"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}\n\n\nbody  {\nzoom: 0.85;\n}\n"}
			</style>
			<script async={true} src={"https://www.googletagmanager.com/gtag/js?id=G-GFK1N3P5RL"} place={"endOfBody"} rawKey={"670146df6aba74b20ebdd83f"} />
			<script place={"endOfBody"} rawKey={"670147228b237590430d8fce"}>
				{"  window.dataLayer = window.dataLayer || [];\n  function gtag(){dataLayer.push(arguments);}\n  gtag('js', new Date());\n\n  gtag('config', 'G-GFK1N3P5RL');"}
			</script>
		</RawHtml>
	</Theme>;
});